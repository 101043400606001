import axios from "axios";
import axiosJsonpAdapter from 'axios-jsonp'

//接続URL
let url = "https://air.veritrans.co.jp/vtdirect/v1/tokens";

export default {

	fetch: async function(callback,card_cvv,card_exp_month,card_exp_year,card_number,client_key) {

		await axios.get(
			url
			+"?card_number="+card_cvv
			+"&card_exp_month="+card_exp_month
			+"&card_exp_year="+card_exp_year
			+"&card_cvv="+card_number
			+"&client_key="+client_key
			, {
				adapter: axiosJsonpAdapter,
				params: {
				}
			}).then(response => callback(response));
	},
};
