export default {
	company: function(card_cvv) {

		// テキスト型に変換
		card_cvv = String(card_cvv);

		// 空、14桁以下でエラー
		if (card_cvv == null || card_cvv == "" || card_cvv.length < 14) return false;

		// 頭2桁取得
		var first2 = parseInt(card_cvv.substr(0, 2));

		// カード会社プレフィクス番号２桁
		var amex = [34, 37];
		var diners = [36];
		var jcb = [30, 31, 33, 35];
		var visa = [40, 41, 42, 43, 44, 45, 46, 47, 48, 49];
		var master = [22, 23, 24, 25, 26, 27, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

		// AMEX判定
		if (amex.some(num => num === first2) && card_cvv.length == 15) return "AMEX";

		// DINERS判定
		if (diners.some(num => num === first2) && card_cvv.length == 14) return "DINERS";

		// JCB判定
		if (jcb.some(num => num === first2) && card_cvv.length == 16) return "JCB";

		// VISA判定
		if (visa.some(num => num === first2) && card_cvv.length == 16) return "VISA";

		// MASTER
		if (master.some(num => num === first2) && card_cvv.length == 16) return "MASTER";
	}
}
