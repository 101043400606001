<template>
	<div>
		<v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top>
			{{ snackbarText }}
			<v-btn text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
		<!-- ___________________________________________________________________________ 日時指定画面 -->
		<v-container class="pa-0"  fluid v-if="(!mode)">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8">
					<v-layout text-left wrap>
            <v-form ref="form" v-model="valid" lazy-validation>
						<v-row  justify="center" class="pa-5 align-start">
							<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<h2>{{shop.name}}&nbsp;ご予約設定</h2>
                <div>{{course.itemName}}</div>
							</v-col>

							<v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8" class="pa-5">
								<v-date-picker
										v-model="reservationDate"
										full-width
										:min="minDate"
										:max="maxDate"
										:allowed-dates="allowedDate"
										class="mt-4"
										locale="ja"
                    @change="reservationDateChage"
								></v-date-picker>
							</v-col>

							<v-col cols="12" xs="12" sm="12" md="10" lg="4" xl="4">

								<v-card
										class="mx-auto"
										max-width="344"
										outlined
								>
									<v-card-title>
                    <v-text-field
                        :rules="reservationDateRules"
                        :text="reservationDate | japaneseDate"
                        :value="reservationDate | japaneseDate"
                        style="pointer-events: none;"
                        required
                    ></v-text-field>
                  </v-card-title>
									<v-card-text>

										<v-select
												v-model="reservationTime"
												:rules="reservationTimeRules"
												:items="reservationTimes"
												label="来店時間"
												required
                        @change="reservationTimeChage"
                        @focus="reservationTimeFocus"
                        :disabled="reservationTimeDisabled"
										></v-select>

										<v-select
												v-model="people"
												:rules="peopleRules"
												:items="peoples"
												label="人数"
												required
                        @focus="peopleFocus"
                        :disabled="peopleDisabled"
                        class="mb-5"
										></v-select>

										<v-textarea
												outlined
												v-model="note"
												label="特記事項"
												value=""
										></v-textarea>

                    <v-checkbox
                        v-model="people_allergyFlag"
                        value="1"
                        label="同席者にアレルギーがある場合はご記入ください"
                    ></v-checkbox>
                    <v-textarea
                        v-if="people_allergyFlag==1"
                        outlined
                        v-model="people_allergy"
                        :rules="people_allergyRules"
                        label="アレルギー内容"
                        value=""
                    ></v-textarea>

										<v-btn
												style="margin-top: 10px"
												color="#e60044"
												dark
												depressed
												tile
												block
												@click="setReservationDateTime"
										>
											予約の確認に進む
										</v-btn>
									</v-card-text>


									<v-card-text>
									</v-card-text>
									<div>

									</div>
								</v-card>

							</v-col>
						</v-row>
            </v-form>
					</v-layout>
				</v-col>
			</v-row>
		</v-container>
		<!-- ___________________________________________________________________________ ログイン画面 -->
		<v-container class="pa-0"  fluid v-if="(mode=='login')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<v-layout text-left wrap class="pa-5">
						<h2>ログイン</h2>
						<v-flex xl12 lg12 md12 sm12 xs12>
							<v-form ref="form" v-model="valid" lazy-validation>
								<v-text-field
										v-model="mailaddress"
										:rules="mailaddressRules"
										label="メールアドレス"
										required
								></v-text-field>

								<v-text-field
										v-model="password"
										:rules="passwordRules"
										label="パスワード"
										type="password"
										required
								></v-text-field>

								<v-row align="center" justify="center">
									<v-col sm="6" md="5" lg="4" xl="4">
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
												block
												@click="goMode()"
										>
											前に戻る
										</v-btn>
									</v-col>
									<v-col sm="6" md="5" lg="4" xl="4">
										<v-btn
												color="#e60044"
												dark
												depressed
												tile
												block
												@click="login"
										>
											ログイン
										</v-btn>
									</v-col>
								</v-row>
								<div class="text-right mt-3">
									<router-link  to="/register"><a>会員登録</a></router-link><br>
									<!--<a>パスワードを忘れた型はこちら</a>-->
								</div>
							</v-form>
						</v-flex>
					</v-layout>
				</v-col>
			</v-row>
		</v-container>

		<!-- ___________________________________________________________________________ カード情報の登録 -->
		<v-container fluid  v-else-if="(mode=='credit')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">


					<h2>カード情報の登録</h2>

					<v-form
							ref="form"
							v-model="valid"
							lazy-validation
					>

						<v-card
								class="mx-auto mb-5 cardForm"
								outlined
						>
							<div id="card-element">
								<!-- Elements will create input elements here -->
							</div>

							<!-- We'll put the error messages in this element -->
							<div id="card-errors" ref="cardErrors" role="alert"></div>


						</v-card>

						<div align="center" class="ma-5">
							<v-btn
									color="#e60044"
									class="ma-2"
									dark
									depressed
									tile
									x-large
									@click="goMode()"
							>
								前に戻る
							</v-btn>
							<v-btn
									color="#e60044"
									class="ma-2"
									dark
									depressed
									tile
									x-large
									@click="payment"
							>
								登録
							</v-btn>
						</div>

					</v-form>

					<v-form ref="form" v-model="valid" lazy-validation>



					</v-form>

				</v-col>
			</v-row>

		</v-container>
		<!-- ___________________________________________________________________________ 確認画面 -->
		<v-container fluid  v-else-if="(mode=='confirm')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<h2>ご予約内容のご確認</h2>

					<v-card
							class="mx-auto mb-5"
							outlined
							max-width="600"
					>
						<v-card-title>{{shop.name}}</v-card-title>
						<v-card-text>
							{{reservationDate | japaneseDate}} {{reservationTime}}<br>
							{{people}} 人<br>
							{{userName}}<br>
							{{reservationCourse}}<br>
						</v-card-text>
					</v-card>

					<v-card
							class="mx-auto mb-5"
							outlined
							max-width="600"
					>
						<v-card-title>特記事項</v-card-title>
						<v-card-text>
							{{note}}
						</v-card-text>
					</v-card>

          <v-card
              class="mx-auto mb-5"
              outlined
              max-width="600"
          >
            <v-card-title>同席者にアレルギーがある場合はご記入ください</v-card-title>
            <v-card-text>
              {{people_allergy}}
            </v-card-text>
          </v-card>

					<v-card
							class="mx-auto mb-5"
							max-width="600"
							outlined
					>
						<v-card-title>ご予約時のお支払い</v-card-title>
						<v-card-text>
							予約手数料：{{fee*people}}円（{{fee}}円 x {{people}}人）
							<!--合計：{{totalPrice}}-->
						</v-card-text>
					</v-card>

					<v-card
							class="mx-auto mb-5"
							max-width="600"
							outlined
					>
						<v-card-title>キャンセルポリシー</v-card-title>
						<v-card-text>
							3日前からのキャンセル：50% <br/>
							前日からのキャンセル：100%
						</v-card-text>
					</v-card>

					<div align="center" class="ma-5">
						<v-btn
								color="#e60044"
								class="ma-2"
								dark
								depressed
								tile
								x-large
								@click="goMode()"
						>
							前に戻る
						</v-btn>
						<v-btn
								color="#e60044"
								class="ma-2"
								dark
								depressed
								tile
								x-large
								@click="apply"
						>
							申し込む
						</v-btn>
					</div>


				</v-col>
			</v-row>
		</v-container>

		<!-- ___________________________________________________________________________ 完了画面 -->
		<v-container fluid  v-else-if="(mode=='fin')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<v-row  align="center" justify="center" class="mt-15">
							ご予約完了しました。
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<!-- ___________________________________________________________________________ エラー画面 -->
		<v-container fluid  v-else-if="(mode=='error')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<v-row  align="center" justify="center" class="mt-15">
						エラーが発生しました
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import axios from "axios";
	import Moment from 'moment'
	import creditCard from "@/vendor/creditcard.js";
	import veritrans from "@/api/veritrans.js";
	import products from "@/api/products.js";
	import shops from "@/api/shops.js";
  import member from "@/api/members";
  import {loadStripe} from "@stripe/stripe-js";




	let loginUrl =process.env.VUE_APP_API_ORIGIN+"/api_mypage/api";
	let reserverUrl =process.env.VUE_APP_API_ORIGIN+"/api_reserver/api";

	let testMode = 0;
  let stripeCode = 'pk_live_51ISc0ADwdqU269ZhCn9o2wd0Bl7qvIjCtZdvjh4rRS6YXjKz1kisSQxSbQcEnKW41qpIrkB91NQP4b5SVYaPGi6k005X5zKF63'
  let stripeUrl = 'https://cheflog.jp/api_cart/stripe_create.php'
	if(process.env.NODE_ENV == 'development') {
		testMode = 1;
    stripeCode = 'pk_test_51ISc0ADwdqU269ZhZXqesJw5D6yJjiaSBJDBP1QqFO7GuAnEGgKqRQfZKo4TDpiuYwA4ggIrReXjbjDOnmsgjuJO00ysOA0qPM'
    stripeUrl = 'http://test.cheflog.jp/api_cart/stripe_create.php'
	}


	export default {
		created() {


			//店舗情報・商品情報の取得
      products.fetch(this.productsInfo, this.$route.query.course);

			//クレジット番号 西暦
			let year = 2020;
			let stop = year+21;
			this.card_exp_years.push('');
			for (year; year < stop; year++) {
				this.card_exp_years.push(year);
			}

			// テスト環境値のセット
			if(testMode) {

				// ダミーのカード番号の登録
				this.card_cvv 		= 4111111111111111
				this.card_exp_month	= '06'
				this.card_exp_year	= 2023
				this.card_number	= 123
			}



		},
		data() {
			return {
				axios,
				loginUrl,
				result: 'none',
				valid: false,
				mode: null,

				// 注文情報
				orderInfo:[],
				userName:null,
				reservationCourse:null,

				// calendar
				minDate:Moment().format(),
				maxDate:Moment().add(2, 'months').format(),
				forbids:[],

				// 店舗情報
				shop:[],

				// コース情報
        course:[],

        // 手数料
				fee:390,

				// ユーザ情報
        member:[],

				// 予約設定
				//reservationDate: new Date().toISOString().substr(0, 10), //来店日
				reservationDate: null, //来店日
        reservationDateRules:[
          v => !!v || 'カレンダーから日付を選択してください',
        ],
				reservationType: null,		//予約種別


        //来店時間
				reservationTime: null,
				reservationTimes: [],
				reservationTimeRules:[
					v => !!v || '来店時間を選択してください',
				],
        reservationTimeDisabled:true,

        //来店日時
				reservationDateTime:null,

        // 人数
				people:null,
				peopleRules:[
					v => !!v || '人数を選択してください',
				],
				peoples:[''],
        peopleDisabled:true,

				note:null,					//備考

        //同席者にアレルギーはあるか?
        people_allergyFlag:0,
        people_allergy:null,
        people_allergyRules:[
          v => !!v || 'アレルギー内容を入力してください',
        ],


				price:390,
				totalPrice:null,
				couponCode:null,

				// ログイン項目情報
				mailaddress: null,		// ログインID
				mailaddressRules: [
					v => !!v || 'メールアドレスを入力してください',
					v => /^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9._-]+)+$/.test(v) || 'メールアドレスを正しく入力してください',
				],
				password: null,		// パスワード
				passwordRules: [
					v => !!v || 'パスワードを入力してください',
					v => v != undefined && v.length >= 6 || 'パスワードを6文字から24文字でご入力ください。',
					v => v != undefined && v.length <= 24 || 'パスワードを6文字から24文字でご入力ください。',
				],

				// 決済情報
				creditCardCompany:null,	//カード会社
				token_id:null,			// トークンID
				client_key:'0b8246c1-2268-4b04-bfe4-1955dd3e81d6',//ベリトランスクライアントキー

				card_cvv:null,			// カード番号
				card_exp_month:null,	// 有効期限（月）
				card_exp_months:['','01','02','03','04','05','06','07','08','09','10','11','12',],
				card_exp_monthsRules:[
					v => !!v || '有効期限（月）を入力してください',
				],

				card_exp_year:null,		// 有効期限（年）
				card_exp_years:[],
				card_exp_yearsRules:[
					v => !!v || '有効期限（年）を入力してください',
				],

				card_number:null,		// セキュリティ番号
				card_numberRules:[
					v => !!v || 'セキュリティ番号を入力してください',
					v => /^\d{1,3}$/.test(v) || '「半角数字」3桁でご入力ください。',
				],


				// スネーク設定
				snackbar: false, //snackbar設定
				snackbarText: "", //	表示テキスト
				snackbarTimeout: 3000, //	タイムアウト秒数



			}
		},
    async mounted() {

    },
		computed: {
			card_cvvRules() {
				const card_cvvRules = []

				// 未入力
				const rule =
							v => !!v || 'カード番号を入力してください'
				card_cvvRules.push(rule)

				// 数字、桁数フォーマット
				const rule2 =
						v => /^[0-9]{14,16}$/.test(v) || '「半角数字」14～16桁のでご入力ください。'
				card_cvvRules.push(rule2)

				// カード会社
				const supportCompanys = ['AMEX','DINERS','JCB','VISA','MASTER']
				//const supportCompanys = ['VISA','MASTER']

				const rule3 =
					v => supportCompanys.some(company => company === creditCard.company(v))
						|| 'カード会社が対応していません。'
				card_cvvRules.push(rule3)

				return card_cvvRules
			},

		},
		methods :{
			/** __________________________________________________________________________ 商品情報の取得 */
			async productsInfo(info) {
				this.course = info.data;

				// 店舗情報の取得
				this.$nextTick(()=>{
          shops.fetch(this.shopInfo,this.$route.query.shop)
        })


			},
			/** __________________________________________________________________________ 店舗情報の取得 */
			async shopInfo(info) {
				this.shop = info.data[0];

        let remainingSeatsOfDates

        // 定休日のセット
        if(this.shop.shop_not_open) {
          this.forbids = this.shop.shop_not_open
        }

        // コース別に予約が埋まった日を取得
        switch(this.course.category1) {
          case 1: case '1': case '': default:
            if(this.shop.remainingSeatsOfDates1) {
              remainingSeatsOfDates = this.shop.remainingSeatsOfDates1.filter(v => v.remaining == 0)
            }
            break;
          case 2: case '2':
            if(this.shop.remainingSeatsOfDates2) {
              remainingSeatsOfDates = this.shop.remainingSeatsOfDates2.filter(v => v.remaining == 0)
            }
            break;
          case 3: case '3':
            if(this.shop.remainingSeatsOfDates3) {
              remainingSeatsOfDates = this.shop.remainingSeatsOfDates3.filter(v => v.remaining == 0)
            }
            break;
        }

        // 埋まった枠を定休日にセット
        if(remainingSeatsOfDates){
          remainingSeatsOfDates.map( v=> this.forbids.push(v.rdate))
        }

      },
      /** __________________________________________________________________________ 日付選択 */
      reservationDateChage(){

        // 来店時間をリセット、disable解除
        this.reservationTime = null
        this.reservationTimeDisabled = false

        // 人数をリセット、disable有効
        this.people = null
        this.peopleDisabled = true


      },
      /** __________________________________________________________________________ 時間選択 */
      reservationTimeChage(){

        // 人数をリセット、disable解除
        this.people=null
        this.peopleDisabled = false

      },
      /** __________________________________________________________________________ 営業時間フォーカス */
      reservationTimeFocus(){

        // リストをリセット
        this.reservationTimes = []

        // 日付から残席数リストを取得
        let remainingSeatsOfTime
        let remainingSeatsOfTimes = []
        if(this.shop.remainingSeatsOfTimes) {
          remainingSeatsOfTimes = this.shop.remainingSeatsOfTimes.filter(v => v.rdate == this.reservationDate)
        }

        // コースから営業時間を判別
        let startHour = 9
        let endHour   = 12
        switch(this.course.category1) {
          case 1: case '1': case '': default:
            startHour = this.shop.shop_start_time
            endHour   = this.shop.shop_end_time
            break;
          case 2: case '2':
            startHour = this.shop.shop_start_time2
            endHour   = this.shop.shop_end_time2
            break;
          case 3: case '3':
            startHour = this.shop.shop_start_time3
            endHour   = this.shop.shop_end_time3
            break;
        }

        // 予約可能時間(現時間+事前予約可能性時間)
        let reservationPossible = Number(Moment().format('H'))+Number(this.shop.shop_reservation_possible)
        console.log("reservationPossible:"+reservationPossible)

        // 営業時間を設定
        let hour
        let remainingSeat = false
        let timeOptions = {}
        for (let step = startHour; step <= endHour; step++) {

          //0で2桁パディング
          hour = ('00' + step).slice(-2)

          // 当日の過去時間をdisable
          if(this.reservationDate == Moment().format('YYYY-MM-DD')) {
            if(Number(Moment().format('H')) > Number(hour)){
              remainingSeat = true
            }
          }

          // 時間から残席数を取得
          if (remainingSeatsOfTimes) {
            remainingSeatsOfTime = remainingSeatsOfTimes.find(v => v.rhour == hour)

            // 残席数が足りない場合にdisable
            if(remainingSeatsOfTime && remainingSeatsOfTime.remaining <= 0){
              remainingSeat = true
            }
          }

          // 当日の場合に開始時間を制御(時間 < 予約可能時間)
          if(this.reservationDate == Moment().format('YYYY-MM-DD')){
            if(Number(hour) < reservationPossible) {
              remainingSeat = true
            }
          }

          timeOptions = {text:hour+":00",value:hour+":00",disabled:remainingSeat}
          this.reservationTimes.push(timeOptions)
          timeOptions = {text:hour+":30",value:hour+":30",disabled:remainingSeat}
          this.reservationTimes.push(timeOptions)

          // リセット
          remainingSeat = false
        }

        //最後のxx:30を消す
        this.reservationTimes.pop()

      },
      /** __________________________________________________________________________ 人数セレクトフォーカス */
      peopleFocus() {

        // リストをリセット
        this.peoples = []

        // 残数を取得
        let remainingSeatsOfTime = []
        if(this.shop.remainingSeatsOfTimes) {
          remainingSeatsOfTime = this.shop.remainingSeatsOfTimes.find(v => v.rdate == this.reservationDate && v.rhour == this.reservationTime.slice(0, 2))
        }

        let remainingSeat = false
        let peoplesOptions = {}
        let remaining = this.shop.shop_max_seats
        for (let step = this.shop.shop_min_people; step <= this.shop.shop_max_people; step++) {

          // 残席数が足りない場合にdisable
          if(remainingSeatsOfTime){
            // 残数を設定
            remaining = remainingSeatsOfTime.remaining
          }


          if(remaining < step){
            remainingSeat = true
          }

          // オブジェクトを追加
          peoplesOptions = {text:step,value:step,disabled:remainingSeat}

          //console.log(peoplesOptions)
          this.peoples.push(peoplesOptions)

          // リセット
          remainingSeat = false
        }
      },
			/** __________________________________________________________________________ Stripe */
			async stripeAsync() {
				this.stripe = await loadStripe(stripeCode)

				console.log(this.stripe);
				const elements = this.stripe.elements()
				this.card = elements.create("card");
				this.card.mount("#card-element");
				this.card.addEventListener('change', ({error}) => {
					const displayError = this.$refs.cardErrors
					if (error) {
						displayError.textContent = error.message;
					} else {
						displayError.textContent = '';
					}
				});


				this.$nextTick(()=>{
					this.mode = 'credit'
				})
			},
			/** __________________________________________________________________________ Stripe */
			stripe() {

				this.stripeAsync()

				this.$nextTick(()=>{
					this.mode = 'credit'
				})
			},
      /** __________________________________________________________________________ ベリトランストークン */
			async veritransInfo(info) {
				this.token_id =  info.data.data.token_id

				this.$nextTick(()=>{
					this.mode = 'confirm'
				})
			},
			/** __________________________________________________________________________ 指定画面に戻る */
			goMode (mode=null) {
				this.mode = mode
			},
			/** __________________________________________________________________________ 指定画面に戻る */
			allowedDate: function (val) {
				if(this.forbids.indexOf(val) === -1) {

					return val
				}

			},
			/** __________________________________________________________________________ 予約日時セット */
			setReservationDateTime(){
				if (this.$refs.form.validate()) {
					this.reservationDateTime = this.reservationDate + " " + this.reservationTime
					this.totalPrice = this.price * this.people

          // トークンチェック
          console.log(this.$store.state.token)
          if(this.$store.state.token) {

            // 会員情報の取得
            member.fetch(this.memberInfo, this.$store.state.token);

            //ページ変更
						this.stripe()
            stop();
          }else {
            this.mode = "login"
            stop();
          }
				}

			},
      /** __________________________________________________________________________ 会員情報の取得 */
      async memberInfo(info) {
        this.member = info.data[0];

      },
			/** __________________________________________________________________________ ログイン */
			login() {

				if (this.$refs.form.validate()) {


					let params = new URLSearchParams();
					params.append("mailaddress", this.mailaddress);
					params.append("password", this.password);

					axios
						.post(loginUrl, params)
						.then(res => {
							this.result = res.data.result;

							if (this.result == "error") {
								switch (res.data.errorCode) {
									case "001":
										this.snackbarText = "メールアドレス、パスワードをご確認ください";
										break;
									case "002":
										this.snackbarText = "メールアドレス、パスワードをご確認ください";
										break
									default:
										this.snackbarText = res.data.errorCode;
								}
								this.snackbar = true;
								stop();
							}

							if (this.result == "success") {
								// vuex store値にトークンを保存
								this.$store.commit("SET_TOKEN", res.data.token);

								this.$nextTick(()=>{

                  // 会員情報の取得
                  member.fetch(this.memberInfo, this.$store.state.token);

                  // ページ変更
                  this.mode = "credit"

                })



							}
						})
						.catch(error => {
							this.snackbarText = error;
							this.snackbar = true;
						});
				}else {
					console.log("err")
				}
			},
			/** __________________________________________________________________________ stripe通信 */
      payment: async function() {

        //async payment() {
        let res = await axios.post(stripeUrl, {
							amount: this.totalPrice+(this.course.price*this.people),              // 金額
							name: this.$store.state.orderInfo.name,                               // 名前
							login_id: this.$store.state.orderInfo.login_id,                       // ログインID
							customer_payment_id: this.$store.state.orderInfo.customer_payment_id, // stripeID
            },
            {
              headers: {
                "Content-Type": "application/json",
                // これはcognitoでサインしていれば、「Amplify.Auth.currentAuthenticatedUser().then((data) => {」から取れるものをstateに入れてるところから取得
                //'Authorization': store.state.user.signInUserSession.idToken.jwtToken,
              }
            }
        )
        //const client_secret = res.data.client_secret;


        const client_secret = res.data.clientSecret;

        //client_secretを利用して（確認情報をStripeに投げて）決済を完了させる
        const confirmRes = await this.stripe.confirmCardPayment(client_secret, {
          payment_method: {
            // card: this.props.elements.getElement('card'),
            card: this.card,
            billing_details: {
              // name: res.data.metadata.username,
            }
          }
        });

        // 決済処理完了
        if(!confirmRes.error) {
          if (confirmRes.paymentIntent.status === "succeeded" ||
              confirmRes.paymentIntent.status === "requires_capture") {

            console.log(confirmRes.paymentIntent.id)

            // 決済IDの保存
            this.orderInfo.credit_token_id = confirmRes.paymentIntent.id

            // 注文処理
            this.mode = "confirm"

          }
        }else {
					alert("カード情報に問題があります。");
				}
      },
			/** __________________________________________________________________________ ベリトランス */
			veritrans(){

				if (this.$refs.form.validate()) {

					//トークンの取得
					veritrans.fetch(this.veritransInfo,
						this.card_cvv,
						this.card_exp_month,
						this.card_exp_year,
						this.card_number,
						this.client_key,
					)

				}
			},
			/** __________________________________________________________________________ 決済 */
			apply() {

				let params = new URLSearchParams();

				params.append("token_id", this.token_id);					      			// トークンID
				params.append("userToken", this.$store.state.token);					// ユーザトークン

				params.append("shop_number", this.shop.no);					      		// ショップ管理番号
				params.append("shop_name", this.shop.name);					      		// ショップ名
				params.append("reservation_date", this.reservationDate);			// 予約日
				params.append("reservation_time", this.reservationTime);			// 予約時間
				params.append("people", this.people);							        		// 人数
				params.append("userName", this.userName);						          // ユーザ名
				params.append("reservation_course", this.course.itemName);	  // コース
				params.append("note", this.note);								              // 特記事項
				params.append("people_allergy", this.people_allergy);         // 同席者にアレルギーはあるか

				params.append("member_num", this.member.no);									// ユーザ管理番号
				params.append("name", this.member.name);											// ユーザ名
				params.append("usermail", this.member.usermail);							// ユーザメールアドレス
				params.append("tel", this.member.tel);							  				// ユーザ電話番号

				params.append("fee", this.fee);								    						// 手数料
				params.append("course_price", this.course.price);							// コース料理金額

				//console.log(params);
				//console.log(paymentUrl)

				axios
					.post(reserverUrl, params)
					.then(res => {
						this.result = res.data.result;

						//console.log(res.data);
						//console.log(this.result);

						if (this.result == "error") {
							switch (res.data.errorCode) {
								case "001":
									this.mode = "credit"// クレジットカード画面
									this.snackbarText = res.data.errorCode + "：カード情報に問題があり予約が出来ませんでした。";
									break;
								case "002":
									this.mode = "credit"// クレジットカード画面
									this.snackbarText = res.data.errorCode + "：カード情報に問題があり予約が出来ませんでした。";
									break
								default:
									this.mode = "error"// エラー画面
									this.snackbarText = res.data.errorCode + "：エラーが発生し予約が出来ませんでした。";
							}
							this.snackbar = true;
							stop();
						}

						if (this.result == "success") {
							//ページ変更
							this.mode = "fin"

						}
					})
					.catch(error => {
						this.snackbarText = error;
						this.snackbar = true;
					});


			},

		}
	}
</script>
<style scoped>
	@import "~@/styles/form.css";
	@import "~@/styles/stripe_global.css";
</style>
