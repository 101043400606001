import axios from "axios";

//接続URL
let url = process.env.VUE_APP_API_ORIGIN+"/api_products/";

export default {

	fetch: async function(callback,content) {
		await axios.get(url + "?no="+content).then(response => callback(response));
	},

	/**
	 * リスト取得
	 *
	 * @param callback
	 * @param query		no指定で単体取得
	 * @returns {Promise<void>}
	 */
	fetchList: async function(callback,query) {

		await axios.get(
			url,
			{
				params: query
			}
		)
			.then(response => callback(response));
	},

	/**
	 * 注文処理
	 * @param callback
	 * @param params
	 * @returns {Promise<void>}
	 */
	order: async function(callback,params) {
		await axios.post(url+"order.php", params)
			.then(response => callback(response));
	},

};
